/**
 * Polyfill stable language features. These imports will be optimized by `@babel/preset-env`.
 *
 * See: https://github.com/zloirock/core-js#babel
 */
import 'core-js/stable';
import 'regenerator-runtime/runtime';

/**
 * Polyfill needed for ReAnimated2 to be able to resolve global var
 * Otherwise a wild 'Uncaught ReferenceError: global is not defined' will appear
 */
(window as any).global = window;

// FIXME need reanimated update, see https://github.com/software-mansion/react-native-reanimated/issues/3355
window['_frameTimestamp'] = null;
